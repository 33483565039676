import React, { useRef } from 'react';
import { TwitchEmbed } from 'react-twitch-embed';

const MyComponent = () => {
  const embed = useRef(); // We use a ref instead of state to avoid rerenders.

  const handleReady = (e) => {
    embed.current = e;
  };

  return (
    <TwitchEmbed channel="freesouldev" autoplay muted withChat darkMode={true} hideControls onVideoReady={handleReady} height="100vh" width="100%"/>
  );
};

export default MyComponent;
